import React , { useState }  from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact/10.png";
import ContactImg2 from "../../assets/img/contact/256x256.png";
import ContactImg3 from "../../assets/img/contact/contact1.svg";
import FullButton from "../Buttons/FullButton";

export default function Contact() {
  const initialFormState = {
    fname: '',
    email: '',
    subject: '',
    message: '',
  };

  const [isMessageSent, setMessageSent] = useState(false);
  const [formData, setFormData] = useState(initialFormState);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // You can add your message sending logic here.
    // For this example, we'll just simulate a successful message sent after 2 seconds.
    setTimeout(() => {
      setMessageSent(true);
      setFormData(initialFormState); // Reset form fields to initial values
    }, 500);
  };

  return (
      <Wrapper id="contact">
        <div className="lightBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Let's get in touch</h1>
              <p className="font13">
                We value your interest and look forward to engaging with you. Whether you have inquiries, partnership proposals, or simply want to share feedback,
                <br />
                This is the gateway to a meaningful connection with our team.
              </p>
            </HeaderInfo>
            <div className="row" style={{ paddingBottom: "30px" }}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form onSubmit={handleSubmit}>
                  <label className="font13">First name:</label>
                  <input type="text" id="fname" name="fname" className="font18 semiBold color"/>
                  <label className="font13">Email:</label>
                  <input type="text" id="email" name="email" className="font18 semiBold color" />
                  <label className="font13">Subject:</label>
                  <input type="text" id="subject" name="subject" className="font18 semiBold color" />
                  <textarea rows="4" cols="50" type="text" id="message" name="message" className="font14 color" placeholder="Message" />
                  <SumbitWrapper className="flex">
                    {/*<ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px",color:"black" }} />*/}
                    <FullButton title="Send Message" to="contact"/>
                  </SumbitWrapper>
                </Form>
                {isMessageSent && <p>Message sent! We will get back to you soon.</p>}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                {/* Add your image elements here */}
                <div style={{ width: "80%" }} className="flexNullCenter flexColumn">
                  <ContactImgBox>
                    <img src={ContactImg1} alt="office" className="radius6" />
                  </ContactImgBox>
                  <ContactImgBox>
                    <img src={ContactImg2} alt="office" className="radius6" />
                  </ContactImgBox>
                </div>
                <div style={{ width: "200%" }}>
                  <div style={{ marginTop: "50px" }}>
                    <img src={ContactImg3} alt="office" className="radius6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #294a95;
  width: 100%;
  padding: 30px;
  outline: none;
  color: #294a95;
  :hover {
    background-color: #294a95;
    border: 1px solid #7620ff;
    color: #294a95;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









