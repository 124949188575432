import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
            <p className="font13">
              This collection of blog posts weaves together insights from diverse domains, covering topics that range from technology and science to arts, culture, and beyond.<br />
              Immerse yourself in a world of thought-provoking articles, expert opinions, and heartwarming anecdotes that celebrate the beauty of human curiosity and the power of sharing ideas            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="New Office!"
                text="Their dedication and attention to detail in handling the latest project were truly commendable.
                Their expertise and problem-solving skills contributed significantly to its success.
                Looking forward to witnessing more of their exceptional work."
                tag="company"
                author="Belkoutbi Hamza"
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="New Office!"
                text="I'm continually impressed by the employee's work at 3olba. Their ability to adapt to changing circumstances and take on new challenges head-on is truly remarkable.
                 Their contributions to the team have been invaluable, and their positive attitude is infectious. Keep up the fantastic work."
                tag="company"
                author="Niya Abderrafie"
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="New Office!"
                text="The employee from 3olba consistently delivers exceptional results! Their passion for their work shines through in every project they undertake. Their teamwork and willingness to go the extra mile have made a significant impact on the overall success of our initiatives.
                It's a pleasure to have such a dedicated and talented colleague on board."
                tag="company"
                author="Kaddouri Abdelghani"
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More"/>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Join us in celebrating the power of feedback and the significance of what they say, as we continue to grow, evolve, and make a meaningful difference in the world.              <br />
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;