import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/8.png";
import ProjectImg2 from "../../assets/img/projects/12.png";
import ProjectImg3 from "../../assets/img/projects/11.png";
import AddImage2 from "../../assets/img/add/10.png";

export default function Projects() {


  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
              We proudly present a collection of remarkable endeavors that exemplify innovation, ingenuity, and excellence.<br />
              Each project showcased here is a testament to the boundless creativity and unwavering dedication of our talented team.</p>
            <p className="font13">
              Join us on this inspiring journey as we take you through a diverse array of endeavors that have redefined industries, enriched lives, and left an indelible mark on the world. </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Transfer Project"
                text="Introducing our groundbreaking Instant Money Transfer Project - a revolutionary platform that empowers you to send and receive
                money with unprecedented speed and ease. Say goodbye to lengthy delays and welcome a new era of seamless,
                real-time financial transactions. "
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Ad For Cab Project"
                text="Innovative platform that revolutionizes advertising through dynamic
                cab ads, empowering businesses to reach their target audience on the
                move while drivers earn with every journey."
                action={() => window.location.href = "https://www.ad4cab.com" }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Blood Donation Project"
                text="Our Blood Donation Project is a noble initiative aimed at making a positive impact on society by fostering a culture
                 of voluntary blood donation. Through this project, we aspire to save lives, one precious drop at a time.
                 By encouraging individuals to become regular blood donors, we ensure a steady and reliable supply of blood for those in need."
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/*<FullButton title="Load More" action={() => alert("clicked")} />*/}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
                We delve into the heart of innovation in IT solutions. This captivating study is a testament to our relentless pursuit of
                creativity and excellence in crafting cutting-edge solutions for our clients. Explore the dynamic process of ideation, problem-solving,
                and collaborative brilliance that fuels our endeavors. With a focus on pushing the boundaries of what's possible
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Contact Us"
                              // action={() => alert("clicked")}
                  />
                </div>
                {/*<div style={{ width: "190px", marginLeft: "15px" }}>*/}
                {/*  <FullButton title="Contact Us" action={() => alert("clicked")} border />*/}
                {/*</div>*/}
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
